import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function AddEditStudentModal({ open, onClose, student, onSave, courses, trainers, coordinators }) {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    email: '',
    phone_number: '',
    course: '',
    assigned_trainer: '',
    assigned_coordinator: '',
    class_time: '',
    course_start_date: null,
    course_end_date: null,
  });

  useEffect(() => {
    if (student) {
      setFormData({
        id: student.ID,
        name: student.data.display_name,
        email: student.data.user_email,
        phone_number: student.phone_number || '',
        course: student.course || '',
        assigned_trainer: student.assigned_trainer || '',
        assigned_coordinator: student.assigned_coordinator || '',
        class_time: student.class_time || '',
        course_start_date: student.course_start_date ? dayjs(student.course_start_date) : null,
        course_end_date: student.course_end_date ? dayjs(student.course_end_date) : null,
      });
    } else {
      setFormData({
        id: '',
        name: '',
        email: '',
        phone_number: '',
        course: '',
        assigned_trainer: '',
        assigned_coordinator: '',
        class_time: '',
        course_start_date: null,
        course_end_date: null,
      });
    }
  }, [student]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDateChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSave = () => {
    onSave({
      ...formData,
      course_start_date: formData.course_start_date ? formData.course_start_date.format('YYYY-MM-DD') : null,
      course_end_date: formData.course_end_date ? formData.course_end_date.format('YYYY-MM-DD') : null
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{student ? 'Edit Student' : 'Add Student'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Phone Number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Course</InputLabel>
              <Select
                name="course"
                value={formData.course}
                onChange={handleChange}
              >
                {courses && courses.length > 0 ? (
                  courses.map((course) => (
                    <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No courses available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Assigned Trainer</InputLabel>
              <Select
                name="assigned_trainer"
                value={formData.assigned_trainer}
                onChange={handleChange}
              >
                {trainers && trainers.length > 0 ? (
                  trainers.map((trainer) => (
                    <MenuItem key={trainer.ID} value={trainer.ID}>{trainer.data.display_name}</MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No trainers available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Assigned Coordinator</InputLabel>
              <Select
                name="assigned_coordinator"
                value={formData.assigned_coordinator}
                onChange={handleChange}
              >
                {coordinators && coordinators.length > 0 ? (
                  coordinators.map((coordinator) => (
                    <MenuItem key={coordinator.id} value={coordinator.id}>{coordinator.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No coordinators available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Class Time"
              name="class_time"
              value={formData.class_time}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Course Start Date"
              value={formData.course_start_date}
              onChange={(newValue) => handleDateChange('course_start_date', newValue)}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Course End Date"
              value={formData.course_end_date}
              onChange={(newValue) => handleDateChange('course_end_date', newValue)}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditStudentModal;