import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  TextField,
  Grid
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, History as HistoryIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import AddEditStudentModal from '../components/AddEditStudentModal';
import AttendanceHistoryModal from '../components/AttendanceHistoryModal';
import { 
  listStudents, 
  getStudent, 
  updateStudent, 
  deleteStudent, 
  getAttendance,
  listCourses,
  listTrainers,
  listCoordinators
} from '../api';

function StudentsPage() {
  const [students, setStudents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAttendanceModal, setOpenAttendanceModal] = useState(false);
  const [editingStudent, setEditingStudent] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchTerm, setSearchTerm] = useState('');
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [courses, setCourses] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [coordinators, setCoordinators] = useState([]);

  const fetchStudents = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listStudents();
      const currentDate = new Date();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      let attendanceData = [];
      try {
        const attendanceResponse = await getAttendance({
          start_date: firstDayOfMonth.toISOString().split('T')[0],
          end_date: lastDayOfMonth.toISOString().split('T')[0]
        });
        
        console.log('Attendance Response:', attendanceResponse);  // For debugging

        // Check if attendanceResponse and its data property exist and are not null
        if (attendanceResponse && attendanceResponse.data) {
          attendanceData = Array.isArray(attendanceResponse.data) 
            ? attendanceResponse.data 
            : [];
        }
      } catch (attendanceError) {
        console.error('Error fetching attendance:', attendanceError);
        // We don't set the main error state here, as we want to continue with student data
      }

      const studentsWithAttendance = await Promise.all(
        response.map(async (student) => {
          const detailedStudent = await getStudent(student.ID);
          const studentAttendance = attendanceData.filter(record => record.student_id === student.ID);
          const totalPresentDays = studentAttendance.filter(record => record.status === 'present').length;

          return { 
            ...detailedStudent, 
            monthlyAttendance: totalPresentDays 
          };
        })
      );

      setStudents(studentsWithAttendance);
    } catch (error) {
      console.error('Error fetching students:', error);
      setError('Failed to fetch students. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchModalData = useCallback(async () => {
    try {
      const [coursesResponse, trainersResponse, coordinatorsResponse] = await Promise.all([
        listCourses(),
        listTrainers(),
        listCoordinators()
      ]);

      setCourses(Array.isArray(coursesResponse) ? coursesResponse : []);
      setTrainers(Array.isArray(trainersResponse) ? trainersResponse : []);
      setCoordinators(Array.isArray(coordinatorsResponse) ? coordinatorsResponse : []);
    } catch (error) {
      console.error('Error fetching modal data:', error);
      setSnackbar({ open: true, message: 'Failed to fetch some data. Some options may be unavailable.', severity: 'warning' });
      // Set empty arrays for any failed fetches
      setCourses([]);
      setTrainers([]);
      setCoordinators([]);
    }
  }, []);

  useEffect(() => {
    fetchStudents();
    fetchModalData();
  }, [fetchStudents, fetchModalData]);

  const handleAddEdit = async (student = null) => {
    if (student) {
      try {
        const detailedStudent = await getStudent(student.ID);
        setEditingStudent(detailedStudent);
      } catch (error) {
        console.error('Error fetching student details:', error);
        setSnackbar({ open: true, message: 'Failed to fetch student details. Please try again.', severity: 'error' });
        return;
      }
    } else {
      setEditingStudent(null);
    }
    setOpenModal(true);
  };

  const handleSave = async (studentData) => {
    try {
      await updateStudent(studentData.id, studentData);
      setSnackbar({ open: true, message: 'Student updated successfully', severity: 'success' });
      setOpenModal(false);
      fetchStudents();
    } catch (error) {
      console.error('Error updating student:', error);
      setSnackbar({ open: true, message: 'Failed to update student. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteStudent(id);
      setSnackbar({ open: true, message: 'Student deleted successfully', severity: 'success' });
      fetchStudents();
    } catch (error) {
      console.error('Error deleting student:', error);
      setSnackbar({ open: true, message: 'Failed to delete student. Please try again.', severity: 'error' });
    }
  };

  const handleOpenAttendanceHistory = (id) => {
    setSelectedStudentId(id);
    setOpenAttendanceModal(true);
  };

  const filteredStudents = students.filter(student => 
    (student.data.display_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
     student.data.user_email.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (!filterEndDate || dayjs(student.course_end_date).isSame(filterEndDate, 'day'))
  );

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          Students
        </Typography>
        <Button 
          startIcon={<AddIcon />} 
          onClick={() => handleAddEdit()} 
          variant="contained" 
          color="primary"
        >
          Add Student
        </Button>
      </Box>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Search by Name or Email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Filter by Course End Date"
            value={filterEndDate}
            onChange={(newValue) => setFilterEndDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Trainer</TableCell>
              <TableCell>Coordinator</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Class Time</TableCell>
              <TableCell>Monthly Attendance</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.map((student) => (
              <TableRow key={student.ID}>
                <TableCell>{student.data.display_name}</TableCell>
                <TableCell>{student.data.user_email}</TableCell>
                <TableCell>{student.phone_number || 'N/A'}</TableCell>
                <TableCell>{student.course || 'N/A'}</TableCell>
                <TableCell>{student.assigned_trainer || 'N/A'}</TableCell>
                <TableCell>{student.assigned_coordinator || 'N/A'}</TableCell>
                <TableCell>{student.course_start_date || 'N/A'}</TableCell>
                <TableCell>{student.course_end_date || 'N/A'}</TableCell>
                <TableCell>{student.class_time || 'N/A'}</TableCell>
                <TableCell>{student.monthlyAttendance || 'N/A'}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleAddEdit(student)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDelete(student.ID)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Attendance History">
                    <IconButton onClick={() => handleOpenAttendanceHistory(student.ID)}>
                      <HistoryIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddEditStudentModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        student={editingStudent}
        onSave={handleSave}
        courses={courses}
        trainers={trainers}
        coordinators={coordinators}
      />
      <AttendanceHistoryModal
        open={openAttendanceModal}
        onClose={() => setOpenAttendanceModal(false)}
        studentId={selectedStudentId}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default StudentsPage;