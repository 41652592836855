import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, CircularProgress } from '@mui/material';
import LoginPage from './LoginPage';
import AdminDashboard from './pages/AdminDashboard';
import TrainerDashboard from './pages/TrainerDashboard';
import StudentDashboard from './pages/StudentDashboard';
import CoursesPage from './pages/CoursesPage';
import StudentsPage from './pages/StudentsPage';
import TrainersPage from './pages/TrainersPage';
import CoordinatorsPage from './pages/CoordinatorsPage';
import TopNavBar from './components/TopNavBar';
import { validateToken, getUserRole } from './api';
import customDarkTheme from './theme';

const AppContent = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
    loading: true,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        await validateToken(token);
        const role = await getUserRole(token);
        setAuthState({
          isAuthenticated: true,
          userRole: role,
          loading: false,
        });
      } catch (error) {
        console.error('Token validation failed:', error);
        localStorage.removeItem('token');
        setAuthState({ isAuthenticated: false, userRole: null, loading: false });
      }
    } else {
      setAuthState({ isAuthenticated: false, userRole: null, loading: false });
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    if (!authState.loading && !authState.isAuthenticated && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [authState, location.pathname, navigate]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setAuthState({
      isAuthenticated: false,
      userRole: null,
      loading: false,
    });
    navigate('/login');
  }, [navigate]);

  if (authState.loading) {
    return <CircularProgress />;
  }

  return (
    <>
      {authState.isAuthenticated && <TopNavBar userRole={authState.userRole} onLogout={handleLogout} />}
      <Container>
        <Routes>
          <Route 
            path="/login" 
            element={
              authState.isAuthenticated ? 
              <Navigate to="/" replace /> : 
              <LoginPage setAuthState={setAuthState} />
            } 
          />
          <Route
            path="/"
            element={
              authState.isAuthenticated ? (
                authState.userRole === 'administrator' ? <AdminDashboard /> :
                authState.userRole === 'trainer' ? <TrainerDashboard /> :
                authState.userRole === 'student' ? <StudentDashboard /> :
                <Navigate to="/login" replace />
              ) : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/courses"
            element={authState.isAuthenticated ? <CoursesPage /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/students"
            element={authState.isAuthenticated && authState.userRole === 'administrator' ? <StudentsPage /> : <Navigate to="/" replace />}
          />
          <Route
            path="/trainers"
            element={authState.isAuthenticated && authState.userRole === 'administrator' ? <TrainersPage /> : <Navigate to="/" replace />}
          />
          <Route
            path="/coordinators"
            element={authState.isAuthenticated && authState.userRole === 'administrator' ? <CoordinatorsPage /> : <Navigate to="/" replace />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Container>
    </>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={customDarkTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Router>
          <AppContent />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;