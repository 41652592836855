import { createTheme } from '@mui/material/styles';

const customDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#38b2ac', // Teal color from the original theme
    },
    background: {
      default: '#0f172a', // This will be the center color of our radial gradient
      paper: '#1e293b', // Slightly lighter blue for paper elements
    },
    text: {
      primary: '#e2e8f0', // Light gray for primary text
      secondary: '#94a3b8', // Lighter gray for secondary text
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'radial-gradient(circle, #0f172a 0%, #000000 100%)',
          minHeight: '100vh',
          backgroundAttachment: 'fixed',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        contained: {
          backgroundColor: '#38b2ac',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#2c9a9f',
          },
        },
        outlined: {
          borderColor: '#38b2ac',
          color: '#38b2ac',
          '&:hover': {
            backgroundColor: 'rgba(56, 178, 172, 0.04)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#4b5563',
            },
            '&:hover fieldset': {
              borderColor: '#38b2ac',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#38b2ac',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#38b2ac',
          '&.Mui-checked': {
            color: '#38b2ac',
          },
        },
      },
    },
  },
});

export default customDarkTheme;