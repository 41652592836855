import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

function AddEditCoordinatorModal({ open, onClose, coordinator, onSave }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState('');

  useEffect(() => {
    if (coordinator) {
      setName(coordinator.name);
      setEmail(coordinator.email);
      setDepartment(coordinator.department || '');
    } else {
      setName('');
      setEmail('');
      setDepartment('');
    }
  }, [coordinator]);

  const handleSave = () => {
    onSave({ id: coordinator ? coordinator.id : null, name, email, department });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{coordinator ? 'Edit Coordinator' : 'Add Coordinator'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Department"
          type="text"
          fullWidth
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditCoordinatorModal;