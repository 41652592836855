import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Box,
  Chip,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  Tooltip
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import AddEditCourseModal from '../components/AddEditCourseModal';
import { listCourses, createCourse, updateCourse, deleteCourse } from '../api';

function CoursesPage() {
  const [courses, setCourses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const fetchCourses = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listCourses();
      console.log('API Response:', response); // For debugging
      if (Array.isArray(response)) {
        setCourses(response);
        console.log('Courses set:', response); // For debugging
      } else {
        console.error('Unexpected API response structure:', response);
        setError('Received invalid data from the server.');
        setCourses([]);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError('Failed to fetch courses. Please try again later.');
      setCourses([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  useEffect(() => {
    console.log('Current courses state:', courses); // For debugging
  }, [courses]);

  const handleAddEdit = (course = null) => {
    setEditingCourse(course);
    setOpenModal(true);
  };

  const handleSave = async (course) => {
    try {
      if (course.id) {
        await updateCourse(course.id, course);
        setSnackbar({ open: true, message: 'Course updated successfully', severity: 'success' });
      } else {
        await createCourse(course);
        setSnackbar({ open: true, message: 'Course created successfully', severity: 'success' });
      }
      setOpenModal(false);
      setEditingCourse(null);
      fetchCourses();
    } catch (error) {
      console.error('Error saving course:', error);
      setSnackbar({ open: true, message: 'Failed to save course. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteCourse(id);
      setSnackbar({ open: true, message: 'Course deleted successfully', severity: 'success' });
      fetchCourses();
    } catch (error) {
      console.error('Error deleting course:', error);
      setSnackbar({ open: true, message: 'Failed to delete course. Please try again.', severity: 'error' });
    }
  };

  const formatDuration = (minutes) => {
    if (minutes == null || isNaN(minutes)) return 'N/A';
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours > 0 && remainingMinutes > 0) {
      return `${hours}h ${remainingMinutes}m`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else {
      return `${remainingMinutes}m`;
    }
  };

  const isGroupClass = (value) => {
    return value === true || value === "true" || value === 1 || value === "1";
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  console.log('Rendering courses:', courses); // For debugging

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          Courses
        </Typography>
        <Button 
          startIcon={<AddIcon />} 
          onClick={() => handleAddEdit()} 
          variant="contained" 
          color="primary"
        >
          Add Course
        </Button>
      </Box>
      {!Array.isArray(courses) || courses.length === 0 ? (
        <Typography>No courses available. Click 'Add Course' to create one.</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((course) => (
                <TableRow key={course.id}>
                  <TableCell>{course.name}</TableCell>
                  <TableCell>{formatDuration(parseInt(course.duration, 10))}</TableCell>
                  <TableCell>
                    <Chip 
                      label={isGroupClass(course.is_group_class) ? "Group" : "Individual"}
                      color={isGroupClass(course.is_group_class) ? "primary" : "secondary"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleAddEdit(course)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDelete(course.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <AddEditCourseModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        course={editingCourse}
        onSave={handleSave}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CoursesPage;