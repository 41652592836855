import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  Chip
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import AddEditTrainerModal from '../components/AddEditTrainerModal';
import { listTrainers, createTrainer, updateTrainer, deleteTrainer } from '../api';

function TrainersPage() {
  const [trainers, setTrainers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingTrainer, setEditingTrainer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const fetchTrainers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listTrainers();
      console.log('API Response:', response); // For debugging
      if (Array.isArray(response)) {
        setTrainers(response);
      } else {
        console.error('Unexpected API response structure:', response);
        setError('Received invalid data from the server.');
        setTrainers([]);
      }
    } catch (error) {
      console.error('Error fetching trainers:', error);
      setError('Failed to fetch trainers. Please try again later.');
      setTrainers([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTrainers();
  }, [fetchTrainers]);

  const handleAddEdit = (trainer = null) => {
    setEditingTrainer(trainer);
    setOpenModal(true);
  };

  const handleSave = async (trainer) => {
    try {
      if (trainer.ID) {
        await updateTrainer(trainer.ID, trainer);
        setSnackbar({ open: true, message: 'Trainer updated successfully', severity: 'success' });
      } else {
        await createTrainer(trainer);
        setSnackbar({ open: true, message: 'Trainer created successfully', severity: 'success' });
      }
      setOpenModal(false);
      setEditingTrainer(null);
      fetchTrainers();
    } catch (error) {
      console.error('Error saving trainer:', error);
      setSnackbar({ open: true, message: 'Failed to save trainer. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteTrainer(id);
      setSnackbar({ open: true, message: 'Trainer deleted successfully', severity: 'success' });
      fetchTrainers();
    } catch (error) {
      console.error('Error deleting trainer:', error);
      setSnackbar({ open: true, message: 'Failed to delete trainer. Please try again.', severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          Trainers
        </Typography>
        <Button 
          startIcon={<AddIcon />} 
          onClick={() => handleAddEdit()} 
          variant="contained" 
          color="primary"
        >
          Add Trainer
        </Button>
      </Box>
      {trainers.length === 0 ? (
        <Typography>No trainers available. Click 'Add Trainer' to create one.</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainers.map((trainer) => (
                <TableRow key={trainer.ID}>
                  <TableCell>{trainer.data.display_name}</TableCell>
                  <TableCell>{trainer.data.user_email}</TableCell>
                  <TableCell>{trainer.data.user_login}</TableCell>
                  <TableCell>
                    {trainer.roles.map((role, index) => (
                      <Chip key={index} label={role} size="small" sx={{ mr: 0.5 }} />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleAddEdit(trainer)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDelete(trainer.ID)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <AddEditTrainerModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        trainer={editingTrainer}
        onSave={handleSave}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default TrainersPage;