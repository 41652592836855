import axios from 'axios';

const BASE_URL = 'https://it2.nilconnect.in/wp-json';
const JWT_BASE_URL = `${BASE_URL}/jwt-auth/v1`;
const SCM_BASE_URL = `${BASE_URL}/scm/v1`;  // Updated to SCM

const api = axios.create({
  baseURL: SCM_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Intercept requests to add the JWT token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Authentication
export const login = (username, password) => {
  return axios.post(`${JWT_BASE_URL}/token`, { username, password });
};

export const googleLogin = async (idToken) => {
  try {
    const response = await axios.post(`${BASE_URL}/nextend-social-login/v1/google-auth`, { id_token: idToken });
    return response.data;
  } catch (error) {
    console.error('Error in googleLogin:', error);
    throw error;
  }
};

export const validateToken = (token) => {
  return axios.post(`${JWT_BASE_URL}/token/validate`, null, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const getUserRole = async (token) => {
  try {
    const response = await axios.get(`${SCM_BASE_URL}/me`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (response.data && response.data.roles && response.data.roles.length > 0) {
      return response.data.roles[0];  // Assuming the first role is the primary role
    } else {
      throw new Error('User role not found in the response');
    }
  } catch (error) {
    console.error('Error fetching user role:', error);
    throw error;
  }
};

// Student Management API
export const listStudents = (params) => api.get('/students', { params });
export const getStudent = (id) => api.get(`/students/${id}`);
export const createStudent = (studentData) => api.post('/students', studentData);
export const updateStudent = (id, studentData) => api.put(`/students/${id}`, studentData);
export const deleteStudent = (id) => api.delete(`/students/${id}`);
export const enrollStudent = (studentId, enrollmentData) => api.post(`/students/${studentId}/enroll`, enrollmentData);

// Trainer Management API
export const listTrainers = (params) => api.get('/trainers', { params });
export const getTrainer = (id) => api.get(`/trainers/${id}`);
export const createTrainer = (trainerData) => api.post('/trainers', trainerData);
export const updateTrainer = (id, trainerData) => api.put(`/trainers/${id}`, trainerData);
export const deleteTrainer = (id) => api.delete(`/trainers/${id}`);
export const assignTrainer = (trainerId, assignmentData) => api.post(`/trainers/${trainerId}/assign`, assignmentData);
export const getTrainerOccupiedHours = (trainerId, date) => api.get(`/trainers/${trainerId}/occupied-hours`, { params: { date } });
export const getTrainerApprovedHours = (trainerId) => api.get(`/trainers/${trainerId}/approved-hours`);
export const setTrainerApprovedHours = (trainerId, hours) => api.post(`/trainers/${trainerId}/approved-hours`, { hours });

// Course Management API
export const listCourses = (params) => api.get('/courses', { params });
export const getCourse = (id) => api.get(`/courses/${id}`);
export const createCourse = (courseData) => api.post('/courses', courseData);
export const updateCourse = (id, courseData) => api.put(`/courses/${id}`, courseData);
export const deleteCourse = (id) => api.delete(`/courses/${id}`);

// Attendance Management API
export const markAttendance = (attendanceData) => api.post('/attendance', attendanceData);
export const getAttendance = (params) => api.get('/attendance', { params });
export const updateAttendance = (id, attendanceData) => api.put(`/attendance/${id}`, attendanceData);
export const createAttendanceReviewRequest = (reviewRequestData) => api.post('/attendance/review-request', reviewRequestData);
export const getAttendanceReviewRequests = (params) => api.get('/attendance/review-requests', { params });
export const updateAttendanceReviewStatus = (id, statusData) => api.put(`/attendance/review-request/${id}`, statusData);

// Room Management API (BigBlueButton Integration)
export const createRoom = (roomData) => api.post('/bbb/rooms', roomData);
export const getRooms = (params) => api.get('/bbb/rooms', { params });
export const getRoom = (id) => api.get(`/bbb/rooms/${id}`);
export const updateRoom = (id, roomData) => api.put(`/bbb/rooms/${id}`, roomData);
export const deleteRoom = (id) => api.delete(`/bbb/rooms/${id}`);
export const joinRoom = (id) => api.get(`/bbb/join/${id}`);
export const endMeeting = (roomData) => api.post('/bbb/end', roomData);

// BBB Recordings
export const getRecordings = (params) => api.get('/bbb/recordings', { params });
export const deleteRecording = (id) => api.delete(`/bbb/recordings/${id}`);

// Study Material Management API
export const createStudyMaterial = (materialData) => api.post('/study-materials', materialData);
export const getStudyMaterial = (id) => api.get(`/study-materials/${id}`);
export const updateStudyMaterial = (id, materialData) => api.put(`/study-materials/${id}`, materialData);
export const deleteStudyMaterial = (id) => api.delete(`/study-materials/${id}`);
export const getStudyMaterials = (params) => api.get('/study-materials', { params });

// User Profile Management
export const updateUserProfile = (profileData) => api.put('/update-profile', profileData);

// Trainer Admin Fields
export const getTrainerAdminFields = (id) => api.get(`/trainers/${id}/admin-fields`);
export const updateTrainerAdminFields = (id, adminFieldsData) => api.put(`/trainers/${id}/admin-fields`, adminFieldsData);

// Hold Requests
export const createHoldRequest = (holdRequestData) => api.post('/hold-request', holdRequestData);
export const getHoldRequests = (params) => api.get('/hold-requests', { params });
export const updateHoldRequest = (id, statusData) => api.put(`/hold-request/${id}`, statusData);

// Manual Hold Creation
export const createManualHold = (manualHoldData) => api.post('/manual-hold', manualHoldData);

// Unassign Trainer
export const unassignTrainer = (unassignData) => api.post('/trainer/unassign', unassignData);

// Coordinator Management API
export const listCoordinators = () => api.get('/coordinators');
export const getCoordinator = (id) => api.get(`/coordinators/${id}`);
export const createCoordinator = (coordinatorData) => api.post('/coordinators', coordinatorData);
export const updateCoordinator = (id, coordinatorData) => api.put(`/coordinators/${id}`, coordinatorData);
export const deleteCoordinator = (id) => api.delete(`/coordinators/${id}`);
export const getCoordinatorStudents = (id) => api.get(`/coordinators/${id}/students`);

// User Management API
export const getCurrentUserDetails = () => api.get('/me');
export const getUserDetails = (id) => api.get(`/users/${id}`);
export const updateUserDetails = (id, userData) => api.put(`/users/${id}`, userData);
export const getAllUsersDetails = () => api.get('/users');
export const updateUserRole = (id, roleData) => api.put(`/users/${id}/role`, roleData);

export default api;