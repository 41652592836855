import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Link, 
  Checkbox, 
  FormControlLabel,
  Divider,
  useMediaQuery,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { GoogleIcon, FacebookIcon } from './CustomIcons';
import Content from './Content';
import { SitemarkIcon } from './CustomIcons';
import { login, getUserRole, googleLogin } from './api';

// Styled components (keep these unchanged)
const RoundedTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
});

const GradientCard = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '450px',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: '20px',
  background: `linear-gradient(135deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
  boxShadow: `0 0 20px 0 rgba(255, 255, 255, 0.1), 0 0 10px 0 rgba(255, 255, 255, 0.06)`,
  border: '2px solid rgba(255, 255, 255, 0.1)',
}));

const FullWidthBox = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'radial-gradient(circle, #0f172a 0%, #000000 100%)',
  backgroundAttachment: 'fixed',
  margin: 0,
  padding: 0,
  [theme.breakpoints.up('md')]: {
    width: '62vw',
    margin: '0 auto',
  },
}));

const CenteredContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4),
  width: '90%',
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'stretch',
    width: '100%',
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '450px',
  [theme.breakpoints.up('md')]: {
    maxWidth: 'calc(50% - 16px)',
  },
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 0',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const LoginPage = ({ setAuthState }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };
  
    const initializeGoogleSignIn = () => {
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: "751838028421-db65oi41q570sqhppfmj25l1jc311400.apps.googleusercontent.com",
          callback: handleGoogleResponse,
          auto_select: false,
          cancel_on_tap_outside: true,
          ux_mode: 'popup',  // Add this line
        });
        console.log("Google Sign-In initialized");
      } else {
        console.error("Google Sign-In script not loaded");
      }
    };
  
    loadGoogleScript();
  
    return () => {
      const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await login(email, password);
      const { token } = response.data;
      const userRole = await getUserRole(token);
      
      localStorage.setItem('token', token);
      
      setAuthState({ isAuthenticated: true, userRole: userRole, loading: false });
      
      redirectBasedOnRole(userRole);
    } catch (error) {
      console.error('Login error:', error);
      setError(error.response?.data?.message || 'Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  

  const handleGoogleLogin = () => {
    if (window.google && window.google.accounts && window.google.accounts.id) {
      try {
        window.google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            console.error('Google Sign-In error:', notification);
            if (notification.getNotDisplayedReason()) {
              console.error('Not displayed reason:', notification.getNotDisplayedReason());
            }
            if (notification.getSkippedReason()) {
              console.error('Skipped reason:', notification.getSkippedReason());
            }
            setError('Google Sign-In could not be displayed. Please try again.');
          }
        });
      } catch (error) {
        console.error('Error initializing Google Sign-In:', error);
        setError('Failed to initialize Google Sign-In. Please try again.');
      }
    } else {
      console.error('Google Sign-In not available');
      setError('Google Sign-In is not available. Please try again later.');
    }
  };

  const handleGoogleResponse = async (response) => {
    console.log('Received Google Sign-In response', response);
    setLoading(true);
    setError('');
    try {
      const authResponse = await googleLogin(response.credential);
      console.log('Backend response to Google login:', authResponse);
      
      const { token, user_email, user_nicename, user_display_name } = authResponse;
      localStorage.setItem('token', token);
      
      const userRole = await getUserRole(token);
      
      setAuthState({ isAuthenticated: true, userRole: userRole, loading: false });
      
      redirectBasedOnRole(userRole);
    } catch (error) {
      console.error('Google login error:', error);
      setError(error.response?.data?.message || 'Google login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const redirectBasedOnRole = (userRole) => {
    switch (userRole) {
      case 'administrator':
        navigate('/admin-dashboard');
        break;
      case 'trainer':
        navigate('/trainer-dashboard');
        break;
      case 'student':
        navigate('/student-dashboard');
        break;
      default:
        setError('Invalid user role');
    }
  };

  const renderContent = () => (
    <ContentWrapper>
      <Content />
    </ContentWrapper>
  );

  const renderSignInCard = () => (
    <GradientCard component="form" onSubmit={handleSubmit}>
      <Typography component="h1" variant="h4" color="text.primary">
        Sign in
      </Typography>
      <RoundedTextField
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <RoundedTextField
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, borderRadius: '20px' }}
        disabled={loading}
      >
        {loading ? 'Signing In...' : 'Sign In'}
      </Button>
      <Link href="#" variant="body2" color="text.secondary">
        Forgot password?
      </Link>
      <Divider sx={{ my: 2 }}>or</Divider>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<GoogleIcon />}
        sx={{ borderRadius: '20px' }}
        onClick={handleGoogleLogin}
        disabled={loading}
      >
        Sign in with Google
      </Button>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<FacebookIcon />}
        sx={{ borderRadius: '20px' }}
      >
        Sign in with Facebook
      </Button>
    </GradientCard>
  );

  return (
    <FullWidthBox>
      {isSmallScreen && (
        <LogoWrapper>
          <SitemarkIcon />
        </LogoWrapper>
      )}
      <CenteredContent>
        {isSmallScreen ? (
          <>
            {renderSignInCard()}
            {renderContent()}
          </>
        ) : (
          <>
            {renderContent()}
            {renderSignInCard()}
          </>
        )}
      </CenteredContent>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </FullWidthBox>
  );
};

export default LoginPage;