import React, { useState, useCallback } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  Container, 
  IconButton, 
  Drawer, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';
import { SitemarkIcon } from '../CustomIcons';
import { styled } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import BookIcon from '@mui/icons-material/Book';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #0f172a, black)',
  boxShadow: '0 4px 10px rgba(0, 150, 255, 0.6), 0 0 20px rgba(0, 150, 255, 0.5)',
  borderRadius: '15px',
  position: 'sticky',
  top: '10px',
  left: '10px',
  right: '10px',
  width: 'auto',
  marginBottom: '20px',
  zIndex: theme.zIndex.drawer + 1,
  border: '0.2px solid white', // Added white border
}));

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 20px',
});

const NavContainer = styled(Container)({
  paddingTop: '10px',
  paddingBottom: '20px',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  background: 'linear-gradient(to bottom, #0f172a, black)',
  zIndex: theme.zIndex.drawer + 1,
  position: 'sticky',
  top: 0,
}));

const TopNavBar = ({ userRole, onLogout }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = useCallback((open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  }, []);

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/dashboard' },
    { text: 'Students', icon: <PeopleIcon />, link: '/students', role: 'administrator' },
    { text: 'Trainers', icon: <SchoolIcon />, link: '/trainers', role: 'administrator' },
    { text: 'Courses', icon: <BookIcon />, link: '/courses', role: 'administrator' },
    { text: 'Coordinators', icon: <SupervisorAccountIcon />, link: '/coordinators', role: 'administrator' },
  ];

  const filteredMenuItems = menuItems.filter(item => !item.role || item.role === userRole);

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <DrawerHeader>
        <SitemarkIcon sx={{ height: 40 }} />
      </DrawerHeader>
      <List>
        {filteredMenuItems.map((item) => (
          <ListItem 
            button 
            key={item.text} 
            component={Link} 
            to={item.link}
            selected={location.pathname === item.link}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <ListItem button onClick={onLogout}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <NavContainer maxWidth={false}>
      <GradientAppBar elevation={3}>
        <StyledToolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SitemarkIcon sx={{ height: 40 }} />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {filteredMenuItems.map((item) => (
              <Button 
                key={item.text} 
                color="inherit" 
                component={Link} 
                to={item.link}
                sx={{ 
                  backgroundColor: location.pathname === item.link ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  }
                }}
              >
                {item.text}
              </Button>
            ))}
            <Button color="inherit" onClick={onLogout}>
              Logout
            </Button>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{ display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </StyledToolbar>
      </GradientAppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            background: 'radial-gradient(circle, #0f172a 0%, #000000 100%)',
            backgroundAttachment: 'fixed',
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </NavContainer>
  );
};

export default React.memo(TopNavBar);