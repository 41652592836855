import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button 
} from '@mui/material';

function AddEditTrainerModal({ open, onClose, trainer, onSave }) {
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (trainer) {
      setDisplayName(trainer.data.display_name || '');
      setEmail(trainer.data.user_email || '');
      setUsername(trainer.data.user_login || '');
    } else {
      setDisplayName('');
      setEmail('');
      setUsername('');
    }
  }, [trainer]);

  const handleSave = () => {
    onSave({
      ID: trainer ? trainer.ID : null,
      data: {
        display_name: displayName,
        user_email: email,
        user_login: username
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{trainer ? 'Edit Trainer' : 'Add Trainer'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Display Name"
          type="text"
          fullWidth
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Username"
          type="text"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditTrainerModal;