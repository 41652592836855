import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Grid, 
  Typography, 
  Avatar, 
  Button, 
  TextField, 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  CircularProgress,
  IconButton,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PhotoCamera, Edit, CloudUpload } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { 
  getCurrentUserDetails, 
  updateUserProfile, 
  getTrainer,
  getStudyMaterials, 
  getAttendance,
  getRooms,
  createHoldRequest,
  getHoldRequests,
  joinRoom
} from '../api';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(135deg, #0f172a, #1e293b)',
  boxShadow: '0 4px 10px rgba(0, 150, 255, 0.6), 0 0 20px rgba(0, 150, 255, 0.5)',
  borderRadius: '15px',
  border: '2px solid rgba(255, 255, 255, 0.1)',
  marginBottom: theme.spacing(3),
}));

const StudentDashboard = () => {
  const [student, setStudent] = useState(null);
  const [trainer, setTrainer] = useState(null);
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [holdRequests, setHoldRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const fetchStudentData = useCallback(async () => {
    try {
      const data = await getCurrentUserDetails();
      setStudent(data);
      if (data.assigned_trainer) {
        const trainerData = await getTrainer(data.assigned_trainer);
        setTrainer(trainerData);
      }
    } catch (err) {
      console.error('Error fetching student data:', err);
      setErrors(prev => ({ ...prev, student: 'Failed to load student data' }));
    }
  }, []);

  const fetchStudyMaterials = useCallback(async () => {
    try {
      const data = await getStudyMaterials();
      setStudyMaterials(data);
    } catch (err) {
      console.error('Error fetching study materials:', err);
      setErrors(prev => ({ ...prev, studyMaterials: 'Failed to load study materials' }));
    }
  }, []);

  const fetchAttendance = useCallback(async () => {
    try {
      const data = await getAttendance({ student_id: student?.id });
      setAttendance(data);
    } catch (err) {
      console.error('Error fetching attendance:', err);
      setErrors(prev => ({ ...prev, attendance: 'Failed to load attendance data' }));
    }
  }, [student]);

  const fetchRooms = useCallback(async () => {
    try {
      const data = await getRooms();
      setRooms(data);
    } catch (err) {
      console.error('Error fetching rooms:', err);
      setErrors(prev => ({ ...prev, rooms: 'Failed to load rooms' }));
    }
  }, []);

  const fetchHoldRequests = useCallback(async () => {
    try {
      const data = await getHoldRequests({ student_id: student?.id });
      setHoldRequests(data);
    } catch (err) {
      console.error('Error fetching hold requests:', err);
      setErrors(prev => ({ ...prev, holdRequests: 'Failed to load hold requests' }));
    }
  }, [student]);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      await fetchStudentData();
      await fetchStudyMaterials();
      await fetchRooms();
      setLoading(false);
    };

    fetchAllData();
  }, [fetchStudentData, fetchStudyMaterials, fetchRooms]);

  useEffect(() => {
    if (student) {
      fetchAttendance();
      fetchHoldRequests();
    }
  }, [student, fetchAttendance, fetchHoldRequests]);

  const handleProfileUpdate = async () => {
    try {
      await updateUserProfile({
        phone_number: student.phone_number,
        bio: student.bio
      });
      setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
      setEditMode(false);
    } catch (err) {
      console.error('Error updating profile:', err);
      setSnackbar({ open: true, message: 'Failed to update profile', severity: 'error' });
    }
  };

  const handleProfilePictureUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append('profile_picture', file);
        await updateUserProfile(formData);
        const updatedStudent = await getCurrentUserDetails();
        setStudent(updatedStudent);
        setSnackbar({ open: true, message: 'Profile picture updated successfully', severity: 'success' });
      } catch (err) {
        console.error('Error uploading profile picture:', err);
        setSnackbar({ open: true, message: 'Failed to upload profile picture', severity: 'error' });
      }
    }
  };

  const handleCreateHoldRequest = async (startDate, endDate, reason) => {
    try {
      await createHoldRequest({
        start_date: startDate,
        end_date: endDate,
        message: reason
      });
      setSnackbar({ open: true, message: 'Hold request created successfully', severity: 'success' });
      fetchHoldRequests();
    } catch (err) {
      console.error('Error creating hold request:', err);
      setSnackbar({ open: true, message: 'Failed to create hold request', severity: 'error' });
    }
  };

  const handleJoinRoom = async (roomId) => {
    try {
      const joinUrl = await joinRoom(roomId);
      window.open(joinUrl, '_blank');
    } catch (err) {
      console.error('Error joining room:', err);
      setSnackbar({ open: true, message: 'Failed to join room', severity: 'error' });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Student Profile */}
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar src={student?.profile_image} sx={{ width: 100, height: 100, mr: 2 }} />
                <Box>
                  <Typography variant="h5">{student?.name}</Typography>
                  <Typography variant="body1">{student?.email}</Typography>
                  {editMode ? (
                    <TextField
                      value={student?.phone_number}
                      onChange={(e) => setStudent({...student, phone_number: e.target.value})}
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  ) : (
                    <Typography variant="body2">{student?.phone_number}</Typography>
                  )}
                </Box>
              </Box>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="icon-button-file"
                type="file"
                onChange={handleProfilePictureUpload}
              />
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
              <TextField
                fullWidth
                label="Bio"
                multiline
                rows={3}
                value={student?.bio || ''}
                onChange={(e) => setStudent({...student, bio: e.target.value})}
                margin="normal"
                disabled={!editMode}
              />
              {editMode ? (
                <Button variant="contained" onClick={handleProfileUpdate}>Save Changes</Button>
              ) : (
                <Button startIcon={<Edit />} onClick={() => setEditMode(true)}>Edit Profile</Button>
              )}
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Course Details */}
        <Grid item xs={12} md={8}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Course Details</Typography>
              <Typography><strong>Course:</strong> {student?.course || 'Not assigned'}</Typography>
              <Typography><strong>Start Date:</strong> {student?.course_start_date ? dayjs(student.course_start_date).format('MMMM D, YYYY') : 'Not set'}</Typography>
              <Typography><strong>End Date:</strong> {student?.course_end_date ? dayjs(student.course_end_date).format('MMMM D, YYYY') : 'Not set'}</Typography>
              <Typography><strong>Class Time:</strong> {student?.class_time || 'Not set'}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Attendance */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Attendance</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attendance.map((record) => (
                      <TableRow key={record.id}>
                        <TableCell>{dayjs(record.date).format('MMMM D, YYYY')}</TableCell>
                        <TableCell>
                          <Chip 
                            label={record.status} 
                            color={record.status === 'present' ? 'success' : 'error'} 
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Study Materials */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Study Materials</Typography>
              <List>
                {studyMaterials.map((material) => (
                  <ListItem key={material.id}>
                    <ListItemText 
                      primary={material.title} 
                      secondary={`Uploaded on: ${dayjs(material.upload_date).format('MMMM D, YYYY')}`} 
                    />
                    <Button 
                      startIcon={<CloudUpload />} 
                      href={material.file_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Download
                    </Button>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Available Rooms */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Available Rooms</Typography>
              <List>
                {rooms.map((room) => (
                  <ListItem key={room.id}>
                    <ListItemText 
                      primary={room.name} 
                      secondary={`Start Time: ${dayjs(room.start_time).format('MMMM D, YYYY HH:mm')}`} 
                    />
                    <Button 
                      variant="contained" 
                      onClick={() => handleJoinRoom(room.id)}
                    >
                      Join
                    </Button>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Hold Requests */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Hold Requests</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {holdRequests.map((request) => (
                      <TableRow key={request.id}>
                        <TableCell>{dayjs(request.start_date).format('MMMM D, YYYY')}</TableCell>
                        <TableCell>{dayjs(request.end_date).format('MMMM D, YYYY')}</TableCell>
                        <TableCell>
                          <Chip 
                            label={request.status} 
                            color={request.status === 'approved' ? 'success' : 'warning'} 
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button 
                variant="contained" 
                onClick={() => {/* Implement create hold request logic */}}
                sx={{ mt: 2 }}
              >
                Create Hold Request
              </Button>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentDashboard;