import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import AddEditCoordinatorModal from '../components/AddEditCoordinatorModal';

function CoordinatorsPage() {
  const [coordinators, setCoordinators] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingCoordinator, setEditingCoordinator] = useState(null);

  useEffect(() => {
    // Fetch coordinators from API
    // For now, we'll use dummy data
    setCoordinators([
      { id: 1, name: 'Eva Brown', email: 'eva@example.com', department: 'IT' },
      { id: 2, name: 'Frank Green', email: 'frank@example.com', department: 'Business' },
    ]);
  }, []);

  const handleAddEdit = (coordinator = null) => {
    setEditingCoordinator(coordinator);
    setOpenModal(true);
  };

  const handleSave = (coordinator) => {
    if (coordinator.id) {
      // Update existing coordinator
      setCoordinators(coordinators.map(c => c.id === coordinator.id ? coordinator : c));
    } else {
      // Add new coordinator
      setCoordinators([...coordinators, { ...coordinator, id: Date.now() }]);
    }
    setOpenModal(false);
    setEditingCoordinator(null);
  };

  const handleDelete = (id) => {
    setCoordinators(coordinators.filter(coordinator => coordinator.id !== id));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Coordinators
      </Typography>
      <Button startIcon={<AddIcon />} onClick={() => handleAddEdit()} variant="contained" color="primary" style={{ marginBottom: '1rem' }}>
        Add Coordinator
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coordinators.map((coordinator) => (
              <TableRow key={coordinator.id}>
                <TableCell>{coordinator.name}</TableCell>
                <TableCell>{coordinator.email}</TableCell>
                <TableCell>{coordinator.department}</TableCell>
                <TableCell>
                  <Button onClick={() => handleAddEdit(coordinator)}>Edit</Button>
                  <Button onClick={() => handleDelete(coordinator.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddEditCoordinatorModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        coordinator={editingCoordinator}
        onSave={handleSave}
      />
    </Container>
  );
}

export default CoordinatorsPage;