import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Card,
  CardContent,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  listCourses,
  getAttendance,
  listTrainers,
  getTrainerOccupiedHours,
  listStudents,
  listCoordinators,
  getHoldRequests,
  getAttendanceReviewRequests,
  getRooms,
} from '../api';
import AddEditCourseModal from '../components/AddEditCourseModal';
import AddEditStudentModal from '../components/AddEditStudentModal';
import AddEditTrainerModal from '../components/AddEditTrainerModal';

const StyledCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #0f172a, black)',
  boxShadow: '0 4px 10px rgba(0, 150, 255, 0.6), 0 0 20px rgba(0, 150, 255, 0.5)',
  borderRadius: '15px',
  border: '0.2px solid white',
  marginBottom: theme.spacing(2),
}));

const AdminDashboard = () => {
  const [courses, setCourses] = useState([]);
  const [averageAttendance, setAverageAttendance] = useState(0);
  const [totalTrainers, setTotalTrainers] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalCoordinators, setTotalCoordinators] = useState(0);
  const [trainerHours, setTrainerHours] = useState({ total: 0, occupied: 0, available: 0 });
  const [holdRequests, setHoldRequests] = useState([]);
  const [attendanceReviewRequests, setAttendanceReviewRequests] = useState([]);
  const [activeRooms, setActiveRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState({ course: false, student: false, trainer: false });
  const [editingItem, setEditingItem] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [
        coursesResponse,
        attendanceResponse,
        trainersResponse,
        studentsResponse,
        coordinatorsResponse,
        holdRequestsResponse,
        attendanceReviewResponse,
        roomsResponse
      ] = await Promise.all([
        listCourses(),
        getAttendance(),
        listTrainers(),
        listStudents(),
        listCoordinators(),
        getHoldRequests(),
        getAttendanceReviewRequests(),
        getRooms()
      ]);

      setCourses(coursesResponse);
      setTotalTrainers(trainersResponse.length);
      setTotalStudents(studentsResponse.length);
      setTotalCoordinators(coordinatorsResponse.length);
      setHoldRequests(holdRequestsResponse);
      setAttendanceReviewRequests(attendanceReviewResponse);
      setActiveRooms(roomsResponse.filter(room => room.status === 'active'));

      // Calculate average attendance
      const totalAttendance = attendanceResponse.reduce((sum, record) => sum + (record.count || 0), 0);
      const averageAttendance = attendanceResponse.length > 0 ? totalAttendance / attendanceResponse.length : 0;
      setAverageAttendance(averageAttendance);

      // Calculate trainer hours
      let totalHours = 0;
      let occupiedHours = 0;

      for (const trainer of trainersResponse) {
        const trainerHoursResponse = await getTrainerOccupiedHours(trainer.ID);
        totalHours += trainerHoursResponse.totalHours || 0;
        occupiedHours += trainerHoursResponse.occupiedHours || 0;
      }

      const availableHours = Math.max(0, totalHours - occupiedHours);
      setTrainerHours({ total: totalHours, occupied: occupiedHours, available: availableHours });

    } catch (err) {
      setError('Failed to fetch data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddEdit = (type, item = null) => {
    setEditingItem(item);
    setModalOpen({ ...modalOpen, [type]: true });
  };

  const handleCloseModal = (type) => {
    setModalOpen({ ...modalOpen, [type]: false });
    setEditingItem(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Total Courses</Typography>
              <Typography variant="h4">{courses.length}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Total Students</Typography>
              <Typography variant="h4">{totalStudents}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Total Trainers</Typography>
              <Typography variant="h4">{totalTrainers}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Total Coordinators</Typography>
              <Typography variant="h4">{totalCoordinators}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Average Daily Attendance</Typography>
              <Typography variant="h4">{averageAttendance.toFixed(2)}%</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={8}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Trainer Hours (Today)</Typography>
              <Typography variant="body1">Total: {trainerHours.total}</Typography>
              <Typography variant="body1">Occupied: {trainerHours.occupied}</Typography>
              <Typography variant="body1">Available: {trainerHours.available}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Hold Requests</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Student</TableCell>
                      <TableCell>Course</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {holdRequests.map((request) => (
                      <TableRow key={request.id}>
                        <TableCell>{request.student_name}</TableCell>
                        <TableCell>{request.course_name}</TableCell>
                        <TableCell>{request.start_date}</TableCell>
                        <TableCell>{request.end_date}</TableCell>
                        <TableCell>
                          <Chip label={request.status} color={request.status === 'pending' ? 'warning' : 'success'} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Attendance Review Requests</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Student</TableCell>
                      <TableCell>Trainer</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Original Status</TableCell>
                      <TableCell>Proposed Status</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attendanceReviewRequests.map((request) => (
                      <TableRow key={request.id}>
                        <TableCell>{request.student_name}</TableCell>
                        <TableCell>{request.trainer_name}</TableCell>
                        <TableCell>{request.date}</TableCell>
                        <TableCell>{request.original_status}</TableCell>
                        <TableCell>{request.proposed_status}</TableCell>
                        <TableCell>
                          <Chip label={request.status} color={request.status === 'pending' ? 'warning' : 'success'} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">Active Rooms</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Room Name</TableCell>
                      <TableCell>Course</TableCell>
                      <TableCell>Trainer</TableCell>
                      <TableCell>Participants</TableCell>
                      <TableCell>Start Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeRooms.map((room) => (
                      <TableRow key={room.id}>
                        <TableCell>{room.name}</TableCell>
                        <TableCell>{room.course_name}</TableCell>
                        <TableCell>{room.trainer_name}</TableCell>
                        <TableCell>{room.participant_count}</TableCell>
                        <TableCell>{room.start_time}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <AddEditCourseModal
        open={modalOpen.course}
        onClose={() => handleCloseModal('course')}
        course={editingItem}
      />

      <AddEditStudentModal
        open={modalOpen.student}
        onClose={() => handleCloseModal('student')}
        student={editingItem}
      />

      <AddEditTrainerModal
        open={modalOpen.trainer}
        onClose={() => handleCloseModal('trainer')}
        trainer={editingItem}
      />
    </Container>
  );
};

export default AdminDashboard;