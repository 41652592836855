import React from 'react';
import { Card, Row, Col, Statistic, Table, Button, Calendar } from 'antd';
import { UserOutlined, BookOutlined, ClockCircleOutlined } from '@ant-design/icons';

function TrainerDashboard() {
  const upcomingSessionsData = [
    { key: 1, course: 'Advanced Python', time: '10:00 AM - 12:00 PM', students: 25 },
    { key: 2, course: 'Web Development', time: '2:00 PM - 4:00 PM', students: 30 },
  ];

  const columns = [
    { title: 'Course', dataIndex: 'course', key: 'course' },
    { title: 'Time', dataIndex: 'time', key: 'time' },
    { title: 'Students', dataIndex: 'students', key: 'students' },
  ];

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Trainer Dashboard</h1>
      <Row gutter={16} className="mb-6">
        <Col span={8}>
          <Card>
            <Statistic title="My Courses" value={4} prefix={<BookOutlined />} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Total Students" value={105} prefix={<UserOutlined />} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Upcoming Sessions" value={2} prefix={<ClockCircleOutlined />} />
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Card title="Upcoming Sessions" extra={<Button type="primary">View All</Button>}>
            <Table columns={columns} dataSource={upcomingSessionsData} pagination={false} />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Schedule">
            <Calendar fullscreen={false} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TrainerDashboard;