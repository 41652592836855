import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControlLabel, Switch } from '@mui/material';

function AddEditCourseModal({ open, onClose, course, onSave }) {
  const [name, setName] = useState('');
  const [duration, setDuration] = useState('');
  const [is_group_class, setIsGroupClass] = useState(false);

  useEffect(() => {
    if (course) {
      setName(course.name || '');
      setDuration(course.duration ? course.duration.toString() : '');
      setIsGroupClass(course.is_group_class || false);
    } else {
      setName('');
      setDuration('');
      setIsGroupClass(false);
    }
  }, [course]);

  const handleSave = () => {
    const courseData = {
      id: course ? course.id : null,
      name,
      duration: parseInt(duration, 10),
      is_group_class
    };
    onSave(courseData);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{course ? 'Edit Course' : 'Add Course'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Course Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Duration (minutes)"
          type="number"
          fullWidth
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={is_group_class}
              onChange={(e) => setIsGroupClass(e.target.checked)}
              color="primary"
            />
          }
          label="Group Class"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditCourseModal;